import React from "react"
import ResponsiveComponent from "../../components/responsive-component"
import Layout from "../../components/layout"
import Graph from "../../components/graph"
import { graphData } from "../../data/graph2"
import "../../styles/analysis.css"
import SEO from "../../components/seo"
class Analysis extends ResponsiveComponent {
  componentDidMount() {
    document.title = "Placements | Expectations IIT Roorkee"
  }
  render() {
    return (
      <Layout>
        <SEO
          title="Placements"
          description="Placement results are a customary way to gauge the potential of an institute, and unfortunately, sometimes the only one. We have analyzed different statistics from this year's placement season to help you get an idea of how things turn out and to put an end to all the myths. The analysis brings out key insights from the number of companies that visit the campus to the multiples profiles that are offered by them, the median CTC and the most-liked placement location"
          image={require("../../images/home/placement.png")}
        />
        {/* <div>
          <h2 className="dark-color-head">Post Graduation</h2>

          <p
            className="normal-para para-container"
            dangerouslySetInnerHTML={{
              __html:
                "Every year, a considerable number of students from IIT-R get selected for reputed MS and PhD programs around the world in colleges including MIT, CalTech, CMU, UC Berkeley, Oxford, etc. A decent CGPA, a few good research projects and good scores in the standard examinations (like GRE) are the prerequisites for converting to such programs. The brand name associated with the IIT and the reputation of college’s alumni always become a handy advantage while applying for research positions in India and abroad.<br><br>What follows are some insights to the post graduation scenario in IIT Roorkee for the current year, where we explore the percentage of graduates choosing to undertake higher studies from each branch, the post-graduation program preferences, and the various countries where students look to further their education. Please note that these statistics are based on current batch strength of each branch, and should not be considered as an absolute measure for future references.",
            }}
          />
          <h4
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
            className="normal-para"
          >
            Total no. of Students Pursuing Master’s or PhD = 63
          </h4>
        </div>
        {graphData.pg.map(g => (
          <div className={g.type}>
            {" "}
            <Graph width={this.state.windowWidth} {...g} />
          </div>
        ))} */}
        <div>
          <h2 className="dark-color-head">Placements</h2>

          <p
            className="normal-para para-container"
            dangerouslySetInnerHTML={{
              __html:
                "Placement results are a customary way to gauge the potential of an institute, and unfortunately, sometimes the only one. We have analyzed different statistics from this year's placement season to help you get an idea of how things turn out and to put an end to all the myths. The analysis brings out key insights from the number of companies that visit the campus to the multiples profiles that are offered by them, the median CTC and the most-liked placement location.<br><br>The analysis provided here is intended only to provide an insight, and it would be inappropriate to draw conclusions, as a lot of students go for higher studies, civil services, and B-school, or are involved in their own start-ups. Thus, they do not actively participate in the placement process.",
            }}
          />
          <i className="normal-para">
            <b>The data provided here is for the placement season 2020-21.</b>
          </i>
          <h4
            style={{
              margin: "2rem auto",
              display: "flex",
              justifyContent: "center",
            }}
            className="normal-para"
          >
            Total no. of companies that recruited = 260 <br />
            <br />
            Number of offers = 818 <br />
            <br />
            Pre-placement offers = 207
          </h4>
        </div>
        {graphData.placement.map(g => (
          <div className={g.type}>
            {" "}
            <Graph width={this.state.windowWidth} {...g} />
          </div>
        ))}
      </Layout>
    )
  }
}

export default Analysis
