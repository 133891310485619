/*Doughnut*/
export const graphData = {
  placement: [
    {
      type: "doughnut",
      data: {
        labels: [
          "Consultancy",
          "Core",
          "Data Science",
          "Finance",
          "Operations & Management",
          "Others",
          "Software",
          "Analyst",
        ],
        datasets: [
          {
            label: "Label",
            backgroundColor: [
              "#4D4D4D",
              "#5DA5DA",
              "#F15854",
              "#60BD68",
              "#F17CB0",
              "#B276B2",
              "#FEBE14",
            ],
            data: [41, 108, 46, 27, 37, 51, 430, 78],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Number of Offers in Each Sector",
            font: { size: 20 },
            padding: 20,
          },
        },
      },
    },
    /*Pie-Chart*/
    {
      type: "pie",
      data: {
        labels: [
          "Abroad",
          "Bangalore",
          "Delhi NCR and Haryana",
          "Hyderabad",
          "Mumbai",
          "Others",
          "PAN India",
          "Pune",
        ],
        datasets: [
          {
            label: "Label",
            backgroundColor: [
              "#4D4D4D",
              "#5DA5DA",
              "#FAA43A",
              "#60BD68",
              "#F17CB0",
              "#B276B2",
              "#F15854",
            ],
            data: [3, 106, 24, 29, 31, 19, 25, 23],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Location-Wise Distribution of Companies",
            font: { size: 20 },
            padding: 20,
          },
        },
      },
    },
    /*Bar-Graph-1*/
    // {
    //   type: "bar",
    //   data: {
    //     labels: [
    //       "Applied Maths",
    //       "Architecture",
    //       "Biotechnology",
    //       "Civil Engineering",
    //       "Chemical Engineering",
    //       "Computer Science Engineering",
    //       "Electronics and Communication Engineering",
    //       "Electrical Engineering",
    //       "Engineering Physics",
    //       "Geological Technology",
    //       "Geo-Physical Technology",
    //       "Mechanical Engineering",
    //       "Metallurgical and Material Science Engineering",
    //       "Physics",
    //       "Polymer Science Engineering",
    //       "Production and Industrial Engineering",
    //       "Paper/Pulp",
    //     ],
    //     datasets: [
    //       {
    //         type: "line",
    //         label: "Number of Companies Opened for All the Branches",
    //         data: [
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //           201,
    //         ],
    //         borderColor: "#F0A24A",
    //         backgroundColor: "rgba(0,0,0,0)",
    //       },
    //       {
    //         label:
    //           "Number of Companies which Opened for Undergraduate Branches.",
    //         backgroundColor: [
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //           "#338CC8",
    //         ],
    //         data: [
    //           243,
    //           211,
    //           231,
    //           251,
    //           251,
    //           307,
    //           312,
    //           311,
    //           222,
    //           210,
    //           215,
    //           236,
    //           236,
    //           207,
    //           222,
    //           244,
    //           217,
    //         ],
    //       },
    //     ],
    //   },
    //   options: {
    //     plugins: {
    //       title: {
    //         display: true,
    //         text: "Number of Companies that Opened for Undergraduate Branches",
    //         font: { size: 20 },
    //         padding: 20,
    //       },
    //     },
    //     maintainAspectRatio: false,
    //     tooltips: {
    //       enabled: true,
    //     },
    //     scales: {
    //       x: {
    //         stacked: true,
    //         ticks: {
    //           autoSkip: false,
    //           maxRotation: 90,
    //           minRotation: 0,
    //         },
    //       },

    //       y: {
    //         ticks: {
    //           beginAtZero: true,
    //         },
    //         title: {
    //           display: true,
    //           text: "Number of Companies",
    //         },
    //       },
    //     },
    //   },
    // },

    /*Bar-Graph-2*/
    {
      type: "bar",
      data: {
        labels: [
          "Applied Mathematics",
          "Architecture and Planning",
          "B. Tech. (Process Engineering) and M.B.A.",
          "Biotechnology",
          "Chemical Engineering",
          "Chemistry",
          "Civil Engineering",
          "Computer Science and Engineering",
          "Electrical Engineering",
          "Electronics & Communication Engineering",
          "Mechanical Engineering",
          "Metallurgical & Materials Engineering",
          "Engineering Physics",
          "Production and Industrial Engineering",
          "Polymer Science",
          "Master of Technology",
          "Earth Science",
        ],
        datasets: [
          {
            label: "Median",
            data: [
              "24.3",
              "10",
              "7",
              "11",
              "13.7",
              "5.5",
              "13.6",
              "27.5",
              "20",
              "20",
              "13",
              "16",
              "13.5",
              "15",
              "11.1",
              "13",
              "12.978",
            ],
            backgroundColor: [
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
              "#1DADC7",
            ],
          },
          {
            label: "Maximum",
            data: [
              "45.31",
              "18",
              "8",
              "20",
              "51.93",
              "10.79",
              "43.3",
              "70",
              "45.6",
              "45.03",
              "37.4",
              "43",
              "56",
              "37.1",
              "20",
              "54.7",
              "33",
            ],
            backgroundColor: [
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
            ],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "CTC",
            font: { size: 20 },
            padding: 20,
          },
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
        },
        scales: {
          y: {
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "CTC(in lacs)",
            },
          },

          x: {
            stacked: true,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 0,
              beginAtZero: true,
            },
          },
        },
      },
    },
    {
      type: "bar",
      data: {
        labels: [
          "2009-10",
          "2010-11",
          "2011-12",
          "2012-13",
          "2013-14",
          "2014-15",
          "2015-16",
          "2016-17",
          "2017-18",
          "2020-19",
          "2019-20",
          "2020-21",
        ],
        datasets: [
          {
            label: "On Campus Offers",
            backgroundColor: [
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
            ],
            data: [
              "853",
              "879",
              "773",
              "889",
              "1055",
              "1186",
              "1036",
              "925",
              "935",
              "1064",
              "928",
              "818", // tofill
            ],
          },
        ],
      },

      options: {
        tooltips: {
          enabled: true,
        },
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Number of On-Campus Offers on a Year Wise Basis",
            font: { size: 20 },
            padding: 20,
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 0,
            },
          },

          y: {
            ticks: {
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Number of On-Campus Offers on a Year Wise Basis",
            },
          },
        },
      },
    },
    {
      type: "bar",
      data: {
        labels: [
          "Applied Maths",
          "Architecture",
          "Biotechnology",
          "Chemical Engineering",
          "Civil Engineering",
          "Computer Science",
          "Electrical engineering",
          "Electronics and communications engineering",
          "Engineering Physics",
          "Geological Technology",
          "Geophysical Technology",
          "Mechanical Engineering",
          "Metallurgy and Material Sciences",
          // "Physics", //NA de rakha data mai
          "Production and Industrial Engineering",
          "Polymer Science",
        ],
        datasets: [
          {
            label: "Median CTC for Male",
            backgroundColor: [
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              // "#338CC8",
              "#338CC8",
              "#338CC8",
            ],
            data: [
              "25",
              "10",
              "10",
              "16.15",
              "17.36",
              "43.3",
              "20",
              "21",
              "16",
              "12",
              "11",
              "14",
              "13",
              // "15",  //NA de rakha iske data mai
              "11.2",
              "16",
            ],
          },
        ],
      },

      options: {
        tooltips: {
          enabled: true,
        },
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Median CTC for Male in the Year 2021",
            font: { size: 20 },
            padding: 20,
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 0,
            },
          },

          y: {
            ticks: {
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Median CTC for Male in the Year 2021",
            },
          },
        },
      },
    },
    {
      type: "bar",
      data: {
        labels: [
          "Applied Maths",
          "Architecture",
          "Biotechnology",
          "Chemical Engineering",
          "Civil Engineering",
          "Computer Science",
          "Electrical engineering",
          "Electronics and communications engineering",
          "Engineering Physics",
          "Geological Technology",
          "Geophysical Technology",
          "Mechanical Engineering",
          "Metallurgy and Material Sciences",
          // "Physics",
          "Production and Industrial Engineering",
          "Polymer Science",
        ],
        datasets: [
          {
            label: "Median CTC for Female",
            backgroundColor: [
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              // "#338CC8",
              "#338CC8",
              "#338CC8",
            ],
            data: [
              "23",
              "18.33",
              "13.5",
              "26.25",
              "13.7",
              "21",
              "25",
              "32",
              "18.355",
              "13.5",
              "16.9",
              "13", // empty field thi ye data mai
              "18.5",
              // "19",
              "27",
              "18.66",
            ],
          },
        ],
      },

      options: {
        tooltips: {
          enabled: true,
        },
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Median CTC for Female in the Year 2021",
            font: { size: 20 },
            padding: 20,
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 0,
            },
          },

          y: {
            ticks: {
              beginAtZero: true,
            },
            title: {
              display: true,
              text: "Median CTC for Female in the Year 2021",
            },
          },
        },
      },
    },
  ],
  pg: [
    /*Bar-Graph-3*/
    {
      type: "bar",
      data: {
        labels: [
          "Applied maths",
          "Biotechnology",
          "Chemical Engineering",
          "Civil engineering",
          "Computer Science",
          "Electrical engineering",
          "Electronics and communications engineering",
          "Mechanical Engineering",
          "Metallurgy and Material Sciences",
          "Physics",
          "Production and Industrial Engineering",
          "Polymer Science",
        ],
        datasets: [
          {
            label: "Percentage of Students Pursuing Higher Studies",
            backgroundColor: [
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
              "#338CC8",
            ],
            data: [
              "8.6",
              "8.7",
              "6.9",
              "4.9",
              "9.3",
              "7.8",
              "7.6",
              "6.1",
              "6.1",
              "16",
              "5",
              "9.1",
            ],
          },
        ],
      },
      options: {
        tooltips: {
          enabled: true,
        },

        // responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text:
              "Percentage of Students Going for Higher Studies on a Branch Wise Basis",
            font: { size: 20 },
            padding: 20,
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 0,
            },
          },

          y: {
            beginAtZero: true,

            title: {
              display: true,
              text: "Percentage of Students Pursuing Higher Studies",
            },
          },
        },
      },
    },
    /*Pie-Chart-2*/
    {
      type: "pie",
      data: {
        labels: ["PhD", "MS / M.Tech", "MBA"],
        datasets: [
          {
            label: "Label",
            backgroundColor: ["#FFCD56", "#FF6384", "#36A2EB"],
            data: ["30", "26", "7"],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Program Preference",
            font: { size: 20 },
            padding: 20,
          },
        },
      },
    },

    /*Pie-Chart-2*/
    {
      type: "pie",
      data: {
        labels: [
          "India",
          "USA",
          "Canada",
          "Korea",
          "Germany",
          "Switzerland",
          "Netherlands",
          "England",
          "France",
        ],
        datasets: [
          {
            label: "Label",
            backgroundColor: [
              "#3E95CD",
              "#8E5EA2",
              "#3CBA9F",
              "#E8C3B9",
              "#C45850",
              "#90EE90",
              "#20B2AA",
              "#9370DB",
              "#F08080",
            ],
            data: ["28", "26", "1", "1", "1", "2", "1", "1", "2"],
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: "Country-Wise Distribution of Postgraduate Students",
            font: {
              size: 20,
            },
            padding: 20,
          },
        },
      },
    },
  ],
}
