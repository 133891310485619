import React from "react"
import { Doughnut, Bar, Pie } from "react-chartjs-2"
import ResponsiveComponent from "./responsive-component"

class Graph extends ResponsiveComponent {
  components = {
    doughnut: Doughnut,
    bar: Bar,
    pie: Pie,
  }
  render() {
    const TagName = this.components[this.props.type]
    return (
      <TagName
        // height={
        //   this.props.type == "bar" ? (this.props.width > 1000 ? 80 : 110) : 100
        // }
        // width={100}
        data={this.props.data}
        options={this.props.options}
      />
    )
  }
}

export default Graph
